/*--------------------------------*/
/* 4 Columns Show, Move Only 1
/*--------------------------------*/
/* Background Color Of Carousel */
.columns_move_1.carousel{
  background: transparent;
}
.columns_move_1 > .carousel-inner > .carousel-item{
  width: 100%;
}
/* Background Images */
.columns_move_1 > .carousel-inner > .carousel-item > .row > .col > img,
.columns_move_1 > .carousel-inner > .carousel-item > .row > .col > a > img{
  width: 100%;
  margin: auto;
  display: block;
}
/*---------------------------------------------*/
/*---------------- Grid System ----------------*/
/*---------------------------------------------*/
.columns_move_1 > .carousel-inner > .carousel-item > .row{
  margin: 0;
}
.columns_move_1 > .carousel-inner > .carousel-item > .row > .col{
  padding: 15px;  /* Change Padding Of Columns */
}
/*------------------------------------------------------------------*/
/*------------ Navigation Buttons Left/Right Properties ------------*/
/*------------------------------------------------------------------*/
.ps_control_left,
.ps_control_left:focus,
.ps_control_right,
.ps_control_right:focus{
  opacity: 1; 			/* Change Opacity    of Navigation Buttons */
  width: 50px; 			/* Change Width      of Navigation Buttons */
  height: 50px;			/* Change Height     of Navigation Buttons */
  color: #3e4243;		/* Change Color      of Navigation Buttons */
  font-size: 30px;		/* Change FontSize   of Navigation Buttons */
  background: #a2a4a5;	/* Change background of Navigation Buttons */
}
.ps_control_left:hover,
.ps_control_right:hover{
  opacity: 1; 			/* Change Opacity    of Navigation Buttons */
  color: #fff;			/* Change Color      of Navigation Buttons */
  background: #3e4243;	/* Change background of Navigation Buttons */
}



#slider_carousel_communities .ps_control_right.ps_top_right_x {
    top: 200px;
    right: -35px;
}
#slider_carousel_communities .ps_control_left.ps_top_left_x {
    top: 200px;
    left: -35px;
}

#slider_carousel_courses .ps_control_right.ps_top_right_x {
    top: 115px;
    right: -35px;
}
#slider_carousel_courses .ps_control_left.ps_top_left_x {
    top: 115px;
    left: -35px;
}

.ps_control_right,.ps_control_left,
.ps_control_right:hover,.ps_control_left:hover,
.ps_control_right:focus,.ps_control_left:focus{
  background: transparent;
  color: #3e4243;
}