.btncomm {
  &--ws {
    white-space: normal;
  }

  &--restricted-word {
    color: #fff;
    background-color: #146b9a;

    &:hover, &:active {
      background-color: #125e87;
    }
  }
}
