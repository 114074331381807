.comunidad {

	&--label {
		background-color: #fbfbfb;
		border: 1px dashed #c3c3c3;
		border-radius: 2px;
		height: 170px;
    width: 270px;

		&__input {
			display: none;
		}

		&__icon {
			color: #dad7d7;
			font-size: 4em;
		}
	}

	&--btn {
		&__crear {
			background-color: #fbfbfb;
			border: 1px solid #efefef;
			box-shadow: #b5b5b5 0 1px 1px;
			color: #656565;

			&__crear:disabled {
				// background-color: #fbfbfb;
				// border: 1px solid #c3c3c3;
				// border-radius: 2px;
				color: #c3c3c3;
				box-shadow: none;
			}
		}
	}
}
/** Taken from https://www.w3schools.com/howto/howto_css_switch.asp **/
/* The switch - the box around the slider */
.switch {
  position: relative;
	display: inline-block;
	width: 45px;
	height: 18px;
	margin: 0;
	margin-left: 5px;
	margin-top: 2px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
	border-radius: 18px;
	font-size: 12px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .2s;
  transition: .2s;
}

.slider:before {
	position: absolute;
	border-radius: 50%;
  content: '';
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .2s;
  transition: .2s;
}

.slider:after {
	content: 'No';
	position: absolute;
	right: 7px;
}

input:checked + .slider {
	background-color: #28a745;
	font-size: 12px;
}

input:focus + .slider {
  box-shadow: 0 0 1px #28a745;
}

input:checked + .slider:after {
	bottom: 0;
	color: #fff;
	content: 'Si';
	left: 7px;
	position: absolute;
}

input:checked + .slider:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(27px);
}
