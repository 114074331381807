.nav-item {
  &:first-of-type {
    > a {
      margin-left: 0;
    }
  }

  &:last-of-type {
    > a {
      margin-right: 0;
    }
  }
}

.nav-link.ctab {
  background-color: #f3f3f3;
  color: #666;
  border-color: #e9ecef #e9ecef #dee2e6;
  box-shadow: inset 0px 1px 0px #fff;
  margin: 0 2px;

  &:hover {
    border-color: #dbddde #dbddde #fff;
  }
}

.nav-link.active.ctab {
  background-color: #006e9d;
  color: #fff;
  border-color: #006fa1 #006fa1 #dbddde;
  box-shadow: inset 0px 1px 0px #008bcc;
}

.community-pane-content {
  border-color: transparent #e9ecef #e9ecef;
  border-width: 1px;
  border-style: solid;
}

.noqueryable {
  display: none;
}
